<template>

  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#kennel"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">畜舎</h1>
      </div>
      <div class="control-group">
        <button
          class="btn btn-warning btn-lg"
          @click.prevent="addAnimal"
        >＋追加</button>
      </div>
    </div>
  </header>

  <main class="p-3">
    <KennelNavi
      @all="showAll"
      @male="showMale"
      @female="showFemale"
      @age="orderbyAge"
      @search="search"
    />

    <ul
      class="kennel-cage grid"
      :class="kennelClass"
    >
      <AnimalCard
        v-for="animal in animalList"
        :key="animal.id"
        :animal='animal'
        :species="species(animal.genus, animal.species)"
      />
    </ul>

  </main>

</template>

<script>
import KennelNavi from '@/components/KennelNavi'
import AnimalCard from '@/components/AnimalCard'
import speciesList from '@/assets/speciesList.js'

export default {
  name: "Kennel",
  emits: ["loaded"],
  data() {
    return {
      modal: false,
      animalList: [],
      preserved: [],
      kennelClass: null,
      order: false,
    }
  },
  components: {
    KennelNavi,
    AnimalCard,
  },
  mounted() {
    this.$store.dispatch('Kennel/loadAnimalList')
    this.$store.watch(
      (state, getters) => getters['Kennel/animalList'],
      (newValue) => {
        this.loading = false
        this.animalList = newValue
        this.preserved = newValue
      }
    )
  },
  methods: {
    addAnimal() {
      this.$router.push('/animaladd')
    },
    showAll() {
      this.kennelClass = ""
    },
    showMale() {
      this.kennelClass = "male"
    },
    showFemale() {
      this.kennelClass = "female"
    },
    orderbyAge() {
      if(this.order){
        this.animalList.sort(function(a, b) {
          return (a.age < b.age) ? -1 : 1
        })
      }else{
        this.animalList.sort(function(a, b) {
          return (a.age > b.age) ? -1 : 1
        })
      }
      this.order = !this.order
    },
    species(genus, id) {
      let res = speciesList[genus].find( (row) => row.id === id )
      return res.name
    },
    search(str) {
      this.animalList = this.preserved
      let extracted = this.animalList.filter( row => row.number.indexOf(str) != -1 )
      this.animalList = extracted
    },
  },
}
</script>