<template>
  <li
    @click='showDetail'
    v-bind:class="animal.sex"
  >
    <div class="card">
      <div class="pic" :class="{ loading: !loaded }">
        <img :src="animal.thumbUrl" v-if="animal.thumbUrl" @load="load">
        <img src="@/assets/ico_dog.png" v-else @load="load">
        <div  v-if="!loaded">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div
          v-if="sex === 'メス'"
          class="num-birth badge bg-warning text-dark"
        >{{animal.numBirth > 0 ? animal.numBirth : 0}}</div>
      </div>
      <div class="card-body">
        <h4>{{animal.name}}</h4>
        <div class="meta">
          <span class="breed">{{species}}</span>
          <span class="sex">{{sex}}</span>
          <span class="age">{{animal.age}}才</span>
          <span class="animal-id">No.{{animal.number}}</span>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  props: ['animal', 'species'],
  data() {
    return {
      sex: null,
      loaded: false
    }
  },
  methods: {
    showDetail() {      
      this.$router.push('/kennel/' + this.$props.animal.id)
    },
    load() {
      this.loaded = true
	  },
  },
  mounted() {
    if( this.$props.animal.sex === 'male' ){
      this.sex = 'オス'
    }
    if( this.$props.animal.sex === 'female' ){
      this.sex = 'メス'
    }
  },
  watch: {
    species: function(newVal) {
      console.log(newVal)
    }
  }
}
</script>
